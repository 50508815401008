* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-color {
  color: #353535;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Tangerine", serif;
  background-color: #FEFEFE;
}

h4 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}

p {
  font-family: "Roboto", serif;
}

button {
  display: flex;
  align-items: center;
  font-family: "Roboto", serif;
  font-weight: normal;
  font-size: 0.9rem;
  width: 150px;
  height: 35px;
  background-color: #ffffff;
  color: #353535;
  border: 1px solid #353535;
  border-radius: 2px;
  cursor: pointer;
}
button span {
  margin-left: 5px;
  margin-right: 5px;
}

input {
  height: 35px;
  border: 1px solid #353535;
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 5px;
}

.container {
  max-width: 1200px;
  min-height: 200px;
  margin: auto;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.modal .modal-card {
  position: relative;
  width: 70%;
  min-height: 250px;
  position: fixed;
  background-color: #ffffff;
  left: 15%;
  top: 80px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #afafaf;
}
.modal .modal-card .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.modal .modal-card .modal-title h2 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  padding-left: 15px;
}
.modal .modal-card .modal-title h2 img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.modal .modal-card .modal-title span {
  padding-right: 15px;
  cursor: pointer;
}
.modal .modal-card .modal-content {
  padding: 10px;
}
.modal .modal-card .modal-content a {
  text-decoration: none;
  color: dodgerblue;
}
.modal .modal-card .modal-content li {
  font-family: "Roboto", serif;
}
.modal .modal-card .modal-content li span {
  font-weight: bold;
}

.modal-author {
  display: none;
}

.modal-about {
  display: none;
}

.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: top linear 0.3s;
}

.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
  transition: right linear 0.3s;
}
.menu .menu-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
}
.menu .menu-header h2 {
  margin-top: 15px;
  font-size: 1.8rem;
}
.menu .menu-content {
  width: 100%;
}
.menu .menu-content .menu-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.menu .menu-content .menu-items .item {
  width: 100%;
  height: 50px;
  display: flex;
}
.menu .menu-content .menu-items .item a {
  width: 100%;
  text-decoration: none;
  padding: 15px;
  font-size: 1.65rem;
  font-weight: bold;
  color: #353535;
  border-bottom: 1px solid #F5F5F5;
  transition: background-color, font-weight, linear 0.3s;
}
.menu .menu-content .menu-items .item a:hover {
  background-color: #F9F9F9;
}

.navbar {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #353535;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  color: #353535;
  margin-left: 25px;
  font-weight: bold;
}
.navbar .logo img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.navbar .btn-menu {
  margin-right: 20px;
  cursor: pointer;
}
.navbar .mode {
  display: flex;
  align-items: center;
}
.navbar .mode #toggle {
  cursor: pointer;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card-link {
  text-decoration: none;
  color: #353535;
}

.card {
  min-width: 250px;
  width: 350px;
  height: 150px;
  display: flex;
  align-items: center;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 2px 2px 10px #afafaf;
  transition: box-shadow linear 0.3s;
}
.card .card-left {
  width: 25%;
  display: flex;
  justify-content: center;
}
.card .card-right {
  width: 75%;
}
.card .card-right h2 {
  margin-bottom: 15px;
  font-size: 1.7rem;
}

.card:hover {
  box-shadow: 0px 0px 1px #afafaf;
}

.card-form {
  min-width: 350px;
  width: 50%;
  margin-bottom: 25px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px #afafaf;
  padding: 20px;
  border-radius: 25px;
}
.card-form h3 {
  margin-bottom: 25px;
  font-size: 1.8rem;
}
.card-form .input-field {
  display: flex;
  align-items: center;
}
.card-form .input-field span {
  margin-right: 10px;
}
.card-form .input-field input {
  width: 100%;
}
.card-form button {
  margin-top: 15px;
  transition: box-shadow linear 0.3s;
}
.card-form button:hover {
  box-shadow: 0px 0px 5px #afafaf;
}

.gen_canvas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gen_canvas #qr {
  width: 125px;
  height: 125px;
  margin-top: 15px;
  margin-bottom: 50px;
  border: 2px solid #353535;
}

footer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #afafaf;
}
footer a {
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  color: #353535;
}
footer a:hover {
  color: #000000;
}
/*# sourceMappingURL=index.d8003e19.css.map */
